.alignleft {
  float: left;
  margin-right: 1rem;
}

.alignright {
  float: right;
  margin-left: 1rem;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
