.comments-area {

  .comment-list {
    @apply sp-mb-8;

    li.comment {
      @apply max-w-xl border sp-mb-4 sp-p-4;

      article {
        @apply relative;

        .comment-meta {
          @apply text-sm;

          .comment-author {
            @apply flex items-start;

            img {
              @apply block size-8 sp-mr-2;
            }

            .says {
              @apply hidden;
            }
          }

          .comment-metadata {
            margin-top: -1rem;
            padding-left: 2.5rem;
            .edit-link {
              @apply underline sp-pl-2;
            }
          }
        }

        .comment-content {
          @apply border-l sp-py-2 sp-pl-2;
          border-inline-start: 1px;
        }

        .reply {
          @apply absolute top-0 right-0;

          a {
            @apply border text-sm sp-py-1 sp-px-2;
          }
        }
      }

      .children {

        li {
          @apply border-r-0 sp-pr-0;
          border-inline-end: 0;
        }
      }
    }
  }

  .comment-respond {
    h3 {
      @apply text-xl font-bold sp-mb-4;
    }

    form {
      @apply border max-w-xl;
      @apply spinoko_shadow spinoko_rounded sp-p-4;

      & > p {
        @apply sp-mb-2;

        &.comment-notes {
          @apply text-sm;
        }
      }

      label {
        @apply block text-sm font-bold sp-mb-1;

        &[for="wp-comment-cookies-consent"] {
          @apply inline font-normal sp-ml-1;
        }
      }

      textarea {
        @apply w-full h-16 border spinoko_rounded sp-p-2;
      }

      input[type="text"],
      input[type="email"],
      input[type="url"] {
        @apply w-full h-8 border;
        @apply spinoko_rounded;
      }

      input[type="submit"] {
        @apply bg-dark text-light font-bold cursor-pointer;
        @apply spinoko_rounded sp-py-1 sp-px-4;
      }
    }
  }
}
