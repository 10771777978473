.admin-bar .site-header {
  @apply sticky top-0;

  @screen md {
    position: fixed;
    top: 32px;
  }

  .main-navigation > div {
    padding-top: 6rem;
  }
}
