/*! purgecss start ignore */
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

embed,
iframe,
object {
  max-width: 100%;
}
/*! purgecss end ignore */
