/*! purgecss start ignore */
.navigation {

  .nav-links {
    @apply flex items-center justify-between font-bold sp-mb-8;

    a {
      @apply text-body;
    }

    .page-numbers {
      width: 2.4rem;
      @apply border border-r-0 border-neutral sp-py-1 sp-px-0 text-center;

      &:first-child {
        border-radius: 0.25rem 0 0 0.25rem;
      }

      &:last-child {
        border-right: 1px solid var(--color-neutral);
        border-radius: 0 0.25rem 0.25rem 0;
      }

      &.current {
        @apply text-neutral;
      }

      svg {
        @apply w-6 h-6 sp-mx-auto;
      }
    }
  }

  &.pagination {
    .nav-links {
      @apply justify-end;
    }
  }

  &.posts-navigation {
    .nav-previous,
    .nav-next {
      a {
        @apply flex items-center;
      }
    }

    .nav-previous {
      @apply sp-ml-auto order-1;
    }
  }
}

body.rtl {
  .navigation {

    .nav-links {
      .page-numbers {
        @apply border-r border-l-0;

        &:first-child {
          border-radius: 0 0.25rem 0.25rem 0;
        }
        &:last-child {
          border-left: 1px solid var(--color-neutral);
          border-radius: 0.25rem 0 0 0.25rem;
        }
      }
    }
  }
}
/*! purgecss end ignore */
