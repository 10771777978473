.show-more-less-block {
  @apply sp-mb-4;

  .less {
    display: none;
  }

  &.toggled {
    .less {
      display: block;
    }
    .more {
      display: none;
    }
  }

  &.option-2 {
    grid-template-columns: 1fr 2rem;
    @apply sp-pt-4 border-t border-b border-neutral grid gap-8 items-start;
  }

  &.option-3 {
    grid-template-columns: 1fr 2rem;
    @apply sp-p-8 sp-pb-4 bg-body spinoko_shadow spinoko_rounded;
  }
}
