.footer-navigation {
  ul {
    @apply flex flex-wrap items-center justify-center;

    li {
      @apply sp-py-0 sp-px-1 font-bold;

      &:not(:last-child) {
        &:after {
          content: '.';
          @apply text-light;
          padding-left: 0.5rem;
        }
      }

      a {
        color: var(--color-light);

        &:hover {
          color: var(--color-neutral);
        }
      }
    }

    .sub-menu {
      @apply block sp-mt-1 sp-pl-2;
    }

    &:has(.sub-menu) {
      @apply items-start justify-start;

      li:after {
        content: '';
      }
    }
  }
}
